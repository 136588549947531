<template>
  <div>
    <v-layout column wrap class="blue-rounded-card pa-2">
      <v-card class="my-2">
        <v-card-title class="pa-0">
          <v-layout align-center>
            <v-btn color="#292F7D" icon medium @click="addModal" :disabled="user_role !== 'CA'">
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
            <span 
              class="title"
              style="font-family: 'Open Sans', 'Arial', monospace !important;"
              :style="user_role === 'CA' ? 'color: #292F7D' : 'color: #00000042'"
            >
              {{ $t('Add New File') }}
            </span>
          </v-layout>
        </v-card-title>
      </v-card>
      <v-flex v-if="tableData">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tableData"
            disable-initial-sort
            :no-data-text="$t('No data available')"
            :rows-per-page-text="`${$t('Rows per page')}:`"
          >
            <template v-slot:pageText="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{ $t('of') }}
              {{ props.itemsLength }}
            </template>
            <template v-slot:items="props">
              <td class="text-xs-center">{{ props.item.name }}</td>
              <td class="text-xs-center">{{ $t(props.item.description) }}</td>
              <td class="text-xs-center">{{ props.item.fileType }}</td>
              <td class="text-xs-center">{{ props.item.expiration }}</td>
              <td class="text-xs-center">{{ props.item.notice }}</td>
              <td :class="lang === 'en' ? 'align-left' : 'align-right'">
                <v-icon @click="edit(props.item)" :disabled="user_role !== 'CA'">mdi-pencil</v-icon>
                <v-icon class="redIcon mx-3" :disabled="user_role !== 'CA'" @click="delModal(props.index, props.item)" >mdi-delete</v-icon>
                <v-icon @click="download(props.item)">mdi-download</v-icon>
                <v-icon v-if="['png', 'jpg'].indexOf(props.item.fileType) > -1" class="mx-3" @click="displayImg(props.item)">mdi-file-find</v-icon>
                <v-icon v-else-if="['pdf'].indexOf(props.item.fileType) > -1" class="mx-3" @click="displayPdf(props.item)">mdi-file-find</v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <transition name="modal" v-if="modalOpen">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div
            :class="modalType === 'view' ? 'modal-container' : 'modalContainer'"
          >
            <div style="position: relative">
              <v-icon class="closeIcon" @click="closeModal" color="white">mdi-close</v-icon>
              <v-layout row wrap class="filesModal">
                <v-flex xs12 class="center mt-0" style="background-color: purple; height:40px; font-family: 'Open Sans', 'Arial', monospace !important;">
                  <h2 v-if="modalType === 'add'" color="purple" class="font-20 font-bold white-text">
                    {{ $t('Add File') }}
                  </h2>
                  <h2 v-if="modalType === 'delete'" color="purple" class="font-20 font-bold white-text">
                    {{ $t('Delete File') }}
                  </h2>
                  <h2 v-if="modalType === 'edit'" color="purple" class="font-20 font-bold white-text">
                    {{ $t('Edit File') }}
                  </h2>
                  <h2 v-if="modalType === 'view'" color="purple" class="font-20 font-bold white-text">
                    {{ $t('View File') }}
                  </h2>
                </v-flex>
                <template v-if="modalType === 'add'">
                  <v-flex xs12 class="center my-3">
                    <v-flex xs4>
                      <label 
                        id="fileupload-label"
                        for="fileupload"
                        :class="lang === 'en' ? 'right' : 'left'"
                      >
                        {{ $t('Choose File') }}...
                      </label>
                      <input
                        type="file"
                        name="fileupload"
                        value="fileupload"
                        id="fileupload"
                        style="display: none"
                        @change="(e) => loadFile(e.target.files[0])"
                      />
                    </v-flex>
                    <v-flex xs8>
                      <span class="mx-2">{{ fileName || $t('No File Chosen') }}</span>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="center my-3">
                    <v-flex xs4>
                      <label
                        :class="lang === 'en' ? 'right' : 'left'"
                        for="fileType"
                        ><span class="userLabels"
                          >{{ $t('File Description') }} :</span
                        ></label
                      >
                    </v-flex>
                    <v-flex xs8>
                      <select v-model="fileDesctiption" class="userInputs">
                        <option
                          v-for="type in fileTypes"
                          :key="type.value"
                          :value="type.value"
                        >
                          {{ type.label }}
                        </option>
                      </select>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="center my-3">
                    <v-flex xs4>
                      <label :class="lang === 'en' ? 'right' : 'left'" for="date"
                        ><span class="userLabels"
                          >{{ $t('Expiration Date') }} :</span
                        ></label
                      >
                    </v-flex>
                    <v-flex xs8>
                      <input
                        type="date"
                        v-model="expiryDate"
                        class="userInputs"
                        :min="`${min_date}`"
                      />
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="center my-3">
                    <v-flex xs4>
                      <label
                        :class="lang === 'en' ? 'right' : 'left'"
                        for="notice"
                        ><span class="userLabels"
                          >{{ $t('Scheduled Notice') }} :</span
                        ></label
                      >
                    </v-flex>
                    <v-flex xs8>
                      <select v-model="notice" class="userInputs">
                        <option
                          v-for="time in noticeTime"
                          :key="time.value"
                          :value="time.value"
                        >
                          {{ time.label }}
                        </option>
                      </select>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="center my-3" v-if="invalidFileName">
                    <h3 class="redIcon">
                      {{ $t('The file name can not include spaces, hebrew letters and some signs') }}
                    </h3>
                  </v-flex>
                  <v-flex xs12 class="center my-3" v-else-if="file_already_exist">
                    <h3 class="redIcon">
                      {{ $t('This file already exists') }}
                    </h3>
                  </v-flex>
                </template>
                <v-flex
                  xs12
                  class="center my-3"
                  v-if="modalType === 'edit'"
                >
                  <v-flex xs4>
                    <label
                      :class="lang === 'en' ? 'right' : 'left'"
                      for="fileType"
                      ><span class="userLabels"
                        >{{ $t('File Description') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs8>
                    <select v-model="editValue.description" class="userInputs">
                      <option
                        v-for="type in fileTypes"
                        :key="type.value"
                        :value="type.value"
                      >
                        {{ type.label }}
                      </option>
                    </select>
                  </v-flex>
                </v-flex>
                <v-flex
                  xs12
                  class="center mt-3 mb-3"
                  v-if="modalType === 'edit'"
                >
                  <v-flex xs4>
                    <label :class="lang === 'en' ? 'right' : 'left'" for="date"
                      ><span class="userLabels"
                        >{{ $t('Expiration Date') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs8>
                    <input
                      type="date"
                      v-model="editValue.expirationDate"
                      class="userInputs"
                      :min="`${min_date}`"
                    />
                  </v-flex>
                </v-flex>
                <v-flex
                  xs12
                  class="center mt-3 mb-3"
                  v-if="modalType === 'edit'"
                >
                  <v-flex xs4>
                    <label
                      :class="lang === 'en' ? 'right' : 'left'"
                      for="notice"
                      ><span class="userLabels"
                        >{{ $t('Scheduled Notice') }} :</span
                      ></label
                    >
                  </v-flex>
                  <v-flex xs8>
                    <select v-model="editValue.noticeDate" class="userInputs">
                      <option
                        v-for="time in noticeTime"
                        :key="time.value"
                        :value="time.value"
                      >
                        {{ time.label }}
                      </option>
                    </select>
                  </v-flex>
                </v-flex>
                <v-flex
                  xs12
                  class="center mt-3 mb-3"
                  v-if="modalType === 'add'"
                >
                  <v-btn
                    color="rgb(129, 46, 129)"
                    :disabled="
                      !fileName ||
                      notice === '' ||
                      fileDesctiption === '' ||
                      expiryDate === '' ||
                      file_already_exist
                    "
                    class="btn-rounded white-text"
                    @click="addFileToStorage"
                    >{{ $t('Add') }}</v-btn
                  >
                </v-flex>
                <v-flex
                  xs12
                  class="center mt-3 mb-3"
                  v-if="modalType === 'edit'"
                >
                  <v-btn
                  class="btn-rounded white-text"
                    color="rgb(129, 46, 129)"
                    :disabled="
                      !editValue ||
                      editValue.noticeDate === '' ||
                      editValue.description === '' ||
                      editValue.expirationDate === ''
                    "
                    @click="editFile"
                    >{{ $t('Edit') }}</v-btn
                  >
                </v-flex>
                <v-flex xs12 class="center mt-3 mb-2" v-if="modalType === 'delete'">
                  <h3>
                    {{ $t('Are You Sure you want to delete this file') }}?
                  </h3>
                </v-flex>
                <v-flex
                  xs12
                  class="center mt-0 mb-3 btn-rounded white-text"
                  v-if="modalType === 'delete'"
                >
                  <v-btn color="rgb(129, 46, 129)" @click="delFile" class="btn-rounded white-text">{{
                    $t('Delete')
                  }}</v-btn>
                </v-flex>
                <v-flex id="showImg" class="center"> </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import {
  getFileList,
  addFile,
  deleteFile,
  downloadFile
} from '@/modules/ApiStorage';
import { setProjectProperty } from '@/modules/ApiUsers';
import Vue from 'vue';
import vuex from '@/store';
export default {
  name: 'DeviceFilesTab',
  props: ['cabinetData'],
  data() {
    return {
      editValue: null,
      tableData: null,
      headers: [
        { text: this.$t('File Name'), value: 'name', align: 'center' },
        {
          text: this.$t('File Description'),
          value: 'description',
          align: 'center'
        },
        { text: this.$t('File Type'), value: 'fileType', align: 'center' },
        {
          text: this.$t('Expiration Date'),
          value: 'expiration',
          align: 'center'
        },
        { text: this.$t('Scheduled Notice'), value: 'notice', align: 'center' },
        {
          text: this.$t('Action'),
          value: 'action',
          align: 'center',
          width: '220px'
        }
      ],
      actionFile: null,
      modalType: '',
      modalOpen: false,
      invalidFileName: false,
      file_already_exist: false,
      lang: this.$store.state.Global.lang,
      fileTypes: null,
      noticeTime: null,
      fileInfo: null,
      fileName: '',
      fileDesctiption: '',
      expiryDate: '',
      notice: '',
      fileExists: false
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      const fileType = this.$store.state.Props.list.find(
        (prop) => prop.name === 'dashboard.cabinet_file_desc'
      );
      if (!fileType) {
        this.setProperty('dashboard.cabinet_file_desc');
      } else {
        const tempFileTypes = [];
        for (const value of fileType.value) {
          tempFileTypes.push({ value, label: this.$t(value) });
        }
        this.fileTypes = tempFileTypes;
        this.fileDesctiption = tempFileTypes[0].value;
      }
      this.noticeTime = [
        { value: 'Expiration date', label: this.$t('Expiration date') },
        {
          value: 'A week before the expiration date',
          label: this.$t('A week before the expiration date')
        },
        {
          value: 'One month before the expiration date',
          label: this.$t('One month before the expiration date')
        },
        {
          value: 'Three months before the expiration date',
          label: this.$t('Three months before the expiration date')
        }
      ];
      this.notice = 'Expiration date';

      const fileInfo = this.$store.state.Props.list.find(
        (prop) => prop.name === 'dashboard.cabinet_file_info'
      );
      if (!fileInfo) {
        this.setProperty('dashboard.cabinet_file_info');
      } else {
        this.fileInfoProp = fileInfo.value;
        this.fileInfo = fileInfo.value.filter(
          (file) => file['cabinet_id'] === this.cabinetData.id
        );
      }
      getFileList(
        this.$store.state.User.project.id,
        this.$route.params.id
      ).then((res) => {
        if (res) {
          const tableData = [];
          for (const i of res) {
            const key = i.Key.split('/');
            const fileNameSpreadDot = key[key.length - 1].split('.');
            const fileType = fileNameSpreadDot[fileNameSpreadDot.length - 1];
            let fileName = '';
            for (let i = 0; i < fileNameSpreadDot.length - 1; i++) {
              if (fileNameSpreadDot.length === 2) {
                fileName += fileNameSpreadDot[i];
              } else {
                if (i === 0) {
                  fileName += fileNameSpreadDot[i];
                } else {
                  fileName += '.' + fileNameSpreadDot[i];
                }
              }
            }
            const description = this.fileInfo.find(
              (val) => val.file_name === fileName + '.' + fileType
            );
            if (!description) {
              tableData.push({
                name: fileName,
                fileType,
                description: '',
                expiration: '',
                notice: ''
              });
            } else {
              tableData.push({
                name: fileName,
                fileType,
                description: description.cabinet_file_description,
                expiration: description.expiration_date,
                notice: description.file_alert_time,
                upload: description.hasOwnProperty('file_upload_time')
                  ? description.file_upload_time
                  : ''
              });
            }
          }
          this.tableData = JSON.parse(JSON.stringify(tableData));
        }
      });
    },
    edit(file) {
      let expiration = '',
        notice = '',
        expirationDate;
      if (file.expiration !== '') {
        expiration =
          file.expiration.substring(6, 10) +
          '-' +
          file.expiration.substring(3, 5) +
          '-' +
          file.expiration.substring(0, 2);
        expirationDate = new Date(
          file.expiration.substring(6, 10),
          file.expiration.substring(3, 5),
          file.expiration.substring(0, 2)
        ).getTime();
      }
      if (file.notice !== '' && expirationDate) {
        const noticeDate = new Date(
          file.notice.substring(6, 10),
          file.notice.substring(3, 5),
          file.notice.substring(0, 2)
        ).getTime();
        if (noticeDate === expirationDate) {
          notice = 'Expiration date';
        } else if (noticeDate === expirationDate - 7 * 86400000) {
          notice = 'A week before the expiration date';
        } else if (noticeDate === expirationDate - 30 * 86400000) {
          notice = 'One month before the expiration date';
        } else {
          notice = 'Three months before the expiration date';
        }
      } else {
        notice = 'Expiration date';
      }
      this.editValue = {
        ...file,
        expirationDate: expiration,
        noticeDate: notice
      };
      this.modalType = 'edit';
      this.modalOpen = true;
    },
    loadFile(file) {
      if (file) {
        this.editValue = file;
        const nameCheck = this.tableData && this.tableData.find((f) => file.name === f.name + '.' + f.fileType);
        this.fileExists = nameCheck ? true : false;
        this.fileName = file.name;
      }
      this.invalidFileName = false;
      this.file_already_exist = false;
    },
    addFileToStorage() {
      this.file_already_exist = this.checkFileAlreadyExist();
      if (this.file_already_exist) return;
      const t = new Date().toLocaleTimeString('it-IT');
      const expirationStr =
        this.expiryDate.substring(8, 10) +
        '-' +
        this.expiryDate.substring(5, 7) +
        '-' +
        this.expiryDate.substring(0, 4) +
        ' ' +
        t;
      const expirationTime = new Date(
        this.expiryDate.substring(0, 4),
        parseInt(this.expiryDate.substring(5, 7), 10) - 1,
        this.expiryDate.substring(8, 10),
        t.substring(0, 2),
        t.substring(3, 5),
        t.substring(6, 8)
      ).getTime();

      let alertTime;
      if (this.notice === 'Expiration date') {
        alertTime = expirationStr;
      } else if (this.notice === 'A week before the expiration date') {
        const tempDate = new Date(expirationTime - 7 * 86400000).toISOString();
        alertTime =
          tempDate.substring(8, 10) +
          '-' +
          tempDate.substring(5, 7) +
          '-' +
          tempDate.substring(0, 4) +
          ' ' +
          t;
      } else if (this.notice === 'One month before the expiration date') {
        const tempDate = new Date(expirationTime - 30 * 86400000).toISOString();
        alertTime =
          tempDate.substring(8, 10) +
          '-' +
          tempDate.substring(5, 7) +
          '-' +
          tempDate.substring(0, 4) +
          ' ' +
          t;
      } else if (this.notice === 'Three months before the expiration date') {
        const tempDate = new Date(expirationTime - 90 * 86400000).toISOString();
        alertTime =
          tempDate.substring(8, 10) +
          '-' +
          tempDate.substring(5, 7) +
          '-' +
          tempDate.substring(0, 4) +
          ' ' +
          t;
      }
      this.invalidFileName = false;
      const validateFileName = !this.editValue.name.match(/^[A-Za-z0-9!@#$%^&*():.+_-]+$/);
      if (validateFileName) {
        this.invalidFileName = true;
        return;
      }

      addFile(this.$route.params.id, this.editValue).then((res) => {
        if (res) {
          const timeStr = new Date().toISOString();
          const time =
            timeStr.substring(8, 10) +
            timeStr.substring(4, 7) +
            '-' +
            timeStr.substring(0, 4) +
            ' ' +
            t;
          const property = this.fileInfoProp;
          property.push({
            file_name: this.editValue.name,
            file_upload_time: time,
            expiration_date:
              this.expiryDate.substring(8, 10) +
              '-' +
              this.expiryDate.substring(5, 7) +
              '-' +
              this.expiryDate.substring(0, 4) +
              ' ' +
              t,
            file_alert_time: alertTime,
            cabinet_file_description: this.fileDesctiption,
            cabinet_id: this.cabinetData.id
          });
          setProjectProperty(
            this.$store.state.User.project.company,
            this.$store.state.User.project.id,
            'dashboard.cabinet_file_info',
            property
          ).then(() => {
            this.getFiles(
              this.$store.state.User.project.id,
              this.$route.params.id
            );
            document.getElementById('fileupload').value = '';
            vuex.dispatch('Global/throwNotify', {
              type: 'success',
              title: this.$t('Success'),
              text: this.$t(`Successfully added file`)
            });
          });
        }else {
          vuex.dispatch('Global/throwNotify', {
            type: 'error', 
            title: `${this.$t('Error')}!`, 
            text: this.$t(`Failed to add file`)
          });
        }
        this.modalType = '';
        this.modalOpen = false;
        this.fileName = '';
      });
    },
    checkFileAlreadyExist(){
      if (this.tableData) {
        const same_file = this.tableData.find((file) => `${file.name}.${file.fileType}` === this.editValue.name);
        if (same_file) return true;
      }
      return false;
    },
    delModal(index, item) {
      this.actionFile = { item, index };
      this.modalType = 'delete';
      this.modalOpen = true;
    },
    delFile() {
      const fileName = this.actionFile.item.name + '.' + this.actionFile.item.fileType;
      const property = this.fileInfoProp;
      for (let i = 0; i < property.length; i++) {
        if (
          property[i].file_name === fileName &&
          property[i].cabinet_id === this.cabinetData.id
        ) {
          property.splice(i, 1);
          break;
        }
      }
      this.fileInfoProp = property;
      setProjectProperty(
        this.$store.state.User.project.company,
        this.$store.state.User.project.id,
        'dashboard.cabinet_file_info',
        property
      ).then(() => {
        deleteFile(this.$store.state.User.project.id, this.$route.params.id, fileName).then((res) => {
          if (res) {
            Vue.set(
              this.tableData,
              this.tableData.splice(this.actionFile.index, 1)
            );
            vuex.dispatch('Global/throwNotify', {
              type: 'success',
              title: this.$t('Success'),
              text: this.$t(`Successfully deleted file`)
            });
          }else {
            vuex.dispatch('Global/throwNotify', {
              type: 'error', 
              title: `${this.$t('Error')}!`, 
              text: this.$t(`Failed to delete file`)
            });
          }
          
          this.modalType = '';
          this.modalOpen = false;
        });
      });
    },
    addModal() {
      this.modalType = 'add';
      this.modalOpen = true;
    },
    download(file) {
      const fileName = file.name + '.' + file.fileType;
      downloadFile(
        this.$store.state.User.project.id,
        this.$route.params.id,
        fileName
      );
    },
    displayImg(file) {
      const fileName = file.name + '.' + file.fileType;
      this.modalType = 'view';
      this.modalOpen = true;
      downloadFile(
        this.$store.state.User.project.id,
        this.$route.params.id,
        fileName,
        'display'
      ).then((res) => {
        const div = document.getElementById('showImg');
        const img = document.createElement('img');
        img.type = 'img';
        img.src = res;
        img.style.padding = '0 30px 0 30px';
        img.style.margin = '30px';
        img.alt = '';
        div.appendChild(img);
      });
    },
    editFile() {
      const t = new Date().toLocaleTimeString('it-IT');
      const expirationStr =
        this.editValue.expirationDate.substring(8, 10) +
        '-' +
        this.editValue.expirationDate.substring(5, 7) +
        '-' +
        this.editValue.expirationDate.substring(0, 4) +
        ' ' +
        t;
      const expirationTime = new Date(
        this.editValue.expirationDate.substring(0, 4),
        parseInt(this.editValue.expirationDate.substring(5, 7), 10) - 1,
        this.editValue.expirationDate.substring(8, 10),
        t.substring(0, 2),
        t.substring(3, 5),
        t.substring(6, 8)
      ).getTime();
      let alertTime;
      if (this.editValue.noticeDate === 'Expiration date') {
        alertTime = expirationStr;
      } else if (
        this.editValue.noticeDate === 'A week before the expiration date'
      ) {
        const tempDate = new Date(expirationTime - 7 * 86400000).toISOString();
        alertTime =
          tempDate.substring(8, 10) +
          '-' +
          tempDate.substring(5, 7) +
          '-' +
          tempDate.substring(0, 4) +
          ' ' +
          t;
      } else if (
        this.editValue.noticeDate === 'One month before the expiration date'
      ) {
        const tempDate = new Date(expirationTime - 30 * 86400000).toISOString();
        alertTime =
          tempDate.substring(8, 10) +
          '-' +
          tempDate.substring(5, 7) +
          '-' +
          tempDate.substring(0, 4) +
          ' ' +
          t;
      } else if (
        this.editValue.noticeDate === 'Three months before the expiration date'
      ) {
        const tempDate = new Date(expirationTime - 90 * 86400000).toISOString();
        alertTime =
          tempDate.substring(8, 10) +
          '-' +
          tempDate.substring(5, 7) +
          '-' +
          tempDate.substring(0, 4) +
          ' ' +
          t;
      }
      let changed = false;
      const tempFilesInfo = JSON.parse(JSON.stringify(this.fileInfoProp));
      for (let file = 0; file < this.fileInfoProp.length; file++) {
        if (
          this.fileInfoProp[file].file_name ===
          this.editValue.name + '.' + this.editValue.fileType
        ) {
          tempFilesInfo.splice(file, 1, {
            cabinet_file_description: this.editValue.description,
            cabinet_id: this.cabinetData.id,
            expiration_date: expirationStr,
            file_alert_time: alertTime,
            file_upload_time: this.fileInfoProp[file].hasOwnProperty(
              'file_upload_time'
            )
              ? this.fileInfoProp[file].file_upload_time
              : '',
            file_name: this.fileInfoProp[file].file_name
          });
          changed = true;
          break;
        }
      }
      if (!changed) {
        const timeStr = new Date().toISOString();
        const time =
          timeStr.substring(8, 10) +
          timeStr.substring(4, 7) +
          '-' +
          timeStr.substring(0, 4) +
          ' ' +
          t;
        tempFilesInfo.push({
          cabinet_file_description: this.editValue.description,
          cabinet_id: this.cabinetData.id,
          expiration_date: expirationStr,
          file_alert_time: alertTime,
          file_upload_time: time,
          file_name: this.editValue.name + '.' + this.editValue.fileType
        });
      }
      this.fileInfoProp = tempFilesInfo;
      setProjectProperty(
        this.$store.state.User.project.company,
        this.$store.state.User.project.id,
        'dashboard.cabinet_file_info',
        tempFilesInfo
      ).then((res) => {
        if (res) {
          for (let row = 0; row < this.tableData.length; row++) {
            if (
              this.tableData[row].name === this.editValue.name &&
              this.editValue.fileType === this.tableData[row].fileType
            ) {
              Vue.set(
                this.tableData,
                this.tableData.splice(row, 1, {
                  description: this.editValue.description,
                  expiration: expirationStr,
                  fileType: this.editValue.fileType,
                  name: this.editValue.name,
                  notice: alertTime
                })
              );
            }
          }
          vuex.dispatch('Global/throwNotify', {
            type: 'success',
            title: this.$t('Success'),
            text: this.$t(`Successfully edited file`)
          });
        }else {
          vuex.dispatch('Global/throwNotify', {
            type: 'error', 
            title: `${this.$t('Error')}!`, 
            text: this.$t(`Failed to edit file`)
          });
        }
        this.modalType = '';
        this.modalOpen = false;
      });
    },
    displayPdf(file) {
      const fileName = file.name + '.' + file.fileType;
      // this.modalType = 'view';
      // this.modalOpen = true;
      downloadFile(
        this.$store.state.User.project.id,
        this.$route.params.id,
        fileName,
        'pdf'
      ).then((res) => {
        window.open(res);
      });
    },
    closeModal() {
      this.invalidFileName = false;
      this.modalOpen = false;
      this.fileName = '';
    },
    async setProperty(type) {
      let value = [];
      if (type === 'dashboard.cabinet_file_desc') {
        value = [
          'Engineer inspection approval',
          'Electrical diagram',
          'Cabinet picture',
          'Cabinet installation site picture',
          'Other'
        ];
      }
      await setProjectProperty(
        this.$store.state.User.project.company,
        this.$store.state.User.project.id,
        type,
        value
      ).then((res) => {
        if (res && res.hasOwnProperty('response')) {
          vuex.commit('Props/add', { ...res.response });
          if (res.response.name === 'dashboard.cabinet_file_desc') {
            this.fileTypes = [
              {
                value: 'Engineer inspection approval',
                label: this.$t('Engineer inspection approval')
              },
              {
                value: 'Electrical diagram',
                label: this.$t('Electrical diagram')
              },
              { value: 'Cabinet picture', label: this.$t('Cabinet picture') },
              {
                value: 'Cabinet installation site picture',
                label: this.$t('Cabinet installation site picture')
              },
              { value: 'Other', label: this.$t('Other') }
            ];
          } else {
            this.fileInfoProp = [];
            this.fileInfo = [];
          }
        }
      });
    }
  },
  computed: {
    min_date(){
      return new Date().toISOString().split('T')[0];
    },
    language() {
      return this.$store.state.Global.lang;
    },
    user_role(){
      return this.$store.state.User.role;
    }
  },
  watch: {
    language(newVal) {
      this.lang = newVal;
      this.headers = [
        { text: this.$t('File Name'), value: 'name', align: 'center' },
        {
          text: this.$t('File Description'),
          value: 'description',
          align: 'center'
        },
        { text: this.$t('File Type'), value: 'fileType', align: 'center' },
        {
          text: this.$t('Expiration Date'),
          value: 'expiration',
          align: 'center'
        },
        { text: this.$t('Scheduled Notice'), value: 'notice', align: 'center' },
        {
          text: this.$t('Action'),
          value: 'action',
          align: 'center',
          width: '220px'
        }
      ];
      this.noticeTime = [
        { value: 'Expiration date', label: this.$t('Expiration date') },
        {
          value: 'A week before the expiration date',
          label: this.$t('A week before the expiration date')
        },
        {
          value: 'One month before the expiration date',
          label: this.$t('One month before the expiration date')
        },
        {
          value: 'Three months before the expiration date',
          label: this.$t('Three months before the expiration date')
        }
      ];
      this.notice = 'Expiration date';
      const tempFileTypes = [];
      for (const value of this.fileTypes) {
        tempFileTypes.push({ value: value.value, label: this.$t(value.value) });
      }
      this.fileTypes = tempFileTypes;
      this.getFiles();
    }
  }
};
</script>
<style scoped>

#fileupload-label {
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: lightgray;
  cursor: pointer;
}

.redIcon {
  color: #e57373;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-container {
  width: fit-content;
  max-width: 98vw;
  max-height: 98vh;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: fit-content;
  overflow-y: scroll;
  overflow-x: scroll;
}

.modalContainer {
  width: fit-content;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  height: fit-content;
}

.closeIcon {
  position: absolute;
  top: 8px;
  right: 15px;
  color: black;
  cursor: pointer;
}
.userLabels {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.userInputs {
  width: 250px;
  height: 24px;
  border: 1px solid grey;
  margin: 0 10px;
  text-align: left;
  padding-left: 5px;
}
.filesModal {
  width: 500px;
}
.align-left {
  align-content: left;
}
.align-right {
  align-content: right;
}
.white-text{
  color: white;
}
.font-20 {
  font-size: 20px;
}
</style>