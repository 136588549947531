import Utils from './Utils';

const host = `${process.env.VUE_APP_HTTP_SERVER}`;
export async function getFileList(projectId, deviceId) {
    const url = host + 'v4/projects/' + projectId + '/devices/' + deviceId + '/storage/?max_items=10&page_size=10'
    const options = {
        method: "GET",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.ok) {
            const resJson = await res.json();
            return resJson.results;
        }
        return null;
    } catch (e) {
        console.error(e);
        return null;
    }
}
export async function addFile(deviceId, file) {
    const url = host + 'v4/devices/' + deviceId + '/remote_store/'
    var formdata = new FormData();
    formdata.append('file', file, file.name);
    const options = {
        method: "POST",
        headers: {
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        },
        body: formdata
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.ok) {
            return true;
        }
        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
}
export async function deleteFile(projectId, deviceId, fileName) {
    const url = host + 'v4/projects/' + projectId + '/devices/' + deviceId + '/storage/'
    var formdata = new FormData();
    formdata.append('filename', fileName.toString());
    const options = {
        method: "DELETE",
        headers: {
            // 'Accept': 'application/json, text/plain, */*',
            // 'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        },
        body: formdata
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.ok) {
            return true;
        }
        return false;
    } catch (e) {
        console.error(e);
        return false;
    }
}
export async function downloadFile(projectId, deviceId, fileName, type = 'download') {
    const url = host + 'v4/projects/' + projectId + '/devices/' + deviceId + '/storage/' + fileName + '/download/'
    const options = {
        method: "GET",
        headers: {
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.status !== 200) {
            console.error("User not found");
            return null;
        }

        const blob = await res.blob();
        let blobUrl;
        if (type !== 'pdf') {
            const newBlob = new Blob([blob]);
            blobUrl = window.URL.createObjectURL(newBlob);
        }
        else {
            const newBlob = new Blob([blob], {type: 'application/pdf'});
            blobUrl = window.URL.createObjectURL(newBlob);
        }
        if (type !== 'download') {
            return blobUrl;
        }
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
        return res;
    } catch (e) {
        console.error(e);
        return null;
    }
}
export async function updateFile(deviceId, fileName) {
    const url = host + 'v4/devices/' + deviceId + '/remote_store/' + fileName;
    const options = {
        method: "GET",
        headers: {
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.ok) {
            const resJson = await res.json();
            return resJson.results;
        }
        return null;
    } catch (e) {
        console.error(e);
        return null;
    }
}